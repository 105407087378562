<!-- [JavaScriptでURLからOGP取得する - Qiita](https://qiita.com/ksyunnnn/items/bfe2b9c568e97bb6b494) -->
<!-- 本当は上のを使いたいけどCORSが効いていてfetchできなかった -->
<!-- cors-anywhereをホストしたらできるかも -->
<template>
  <a
    id="blogcard"
    :href="url"
    class="block no-underline text-black border-solid border rounded m-4 p-4"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div v-if="loading">loading...</div>
    <div v-else-if="error">
      <div>
        {{ error }}
      </div>
      <div>failed to get blog-card of {{ url }}</div>
    </div>
    <div v-else class="flex">
      <div class="flex justify-center align-center w-2/5">
      <!-- <div id="blogcard-img" class="flex justify-center align-center w-2/5"> -->
        <img
          v-if="image"
          :src="image"
          :alt="description"
          loading="lazy"
          class="block"
          height="120"
        />
        <span v-else class="block">no image</span>
      </div>
      <div class="w-3/5">
        <div class="">
          <div class="text-lg font-medium">{{ title }}</div>
          <div class="text-sm overflow-hidden" id="description">
            {{ description }}
          </div>
        </div>
        <div class="mt-2">
          <img
            :src="favicon"
            alt="favicon"
            loading="lazy"
            width="16"
            height="16"
          />
          {{ domain }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import fetch from "node-fetch";
const X_API_KEY = "Atude8FUz96kU5EJCTNg";
export default {
  name: "BlogCard",
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      title: "",
      description: "",
      image: "",
      domain: "",
      favicon: "",
      loading: true,
      error: "",
    };
  },
  created() {
    this.domain = this.url.replace(/.*\/\/([^/]+)\/.*/, "$1");
    this.favicon = `https://www.google.com/s2/favicons?domain=${this.domain}`;

    fetch("https://kawarimi-deno-api.vercel.app/ogp-json?url=" + this.url, {
      headers: { "Content-Type": "application/json", "X-API-KEY": X_API_KEY },
    })
      .then(async (res) => await res.json())
      .then((json) => {
        // console.log(json);
        this.title = json["og:title"];
        this.description = json["og:description"];
        this.image = json["og:image"];
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#blogcard:not(:hover) {
  color: #000;
}
#description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#blogcard-img {
  width: 180px;
}
</style>
<!-- [複数行テキストの末尾省略をCSSのみで実現する - Qiita](https://qiita.com/ezawa800/items/534e36f819e9ad2712af) -->
